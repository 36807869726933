
.wrapper {
    max-width: 1200px;
    /* border: 1px solid red; */
    margin: 0 auto;
    /* height: auto !important; */
    height: 100%;
    padding: 60px 20px 60px 20px;
}
.title {
    /* margin-top: 50px; */
    font-size: 60px;
    font-weight: bolder;
    color: black;
}
@media only screen and (max-width: 480px) {
    .title {
        font-size: 40px;
    }
}

.home {
    /* height: 60vh; */
    /* background: rgba(255, 255, 255, 0.5); */
    background-color: white;
    display: block;
}
.motto-container {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: 50px;
    /* border: 1px solid red; */
    /* position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); */
}
.motto {
    font-size: 60px;
    font-weight: 700;
    line-height: 60px;
}
.motto .support {
    color: #D72127;
}
.motto .success {
    color: #F7BD23;
}
.sub-motto {
    margin-top: 10px;
    font-size: 20px;
}
.home .cto {
    margin-top: 30px;
}
.home .button {
    /* background-color: #04AA6D; */
    border: none;
    color: black;
    border: 1px solid black;
    border-radius: 30px;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    margin-right: 10px;
}
.home .button:hover {
    background-color: black;
    border: none;
    color: white;
    border: 1px solid black;
    border-radius: 30px;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    margin-right: 10px;
}
video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -5;
}
.nav-logo {
    height: 40px;
}

/* services styles here */
.services {
    /* height: 50vh; */
    background-color: #F8F8F8;
    /* padding: 60px 0; */
}
.services-container {
    /* border: 1px solid blue; */
    display: flex;
    margin-top: 30px;
}
@media only screen and (max-width: 480px) {
    .services-container {
        display: flex;
        flex-direction: column;
    }
    .each-service {
        margin-bottom: 20px;
    }
}

.each-service {
    /* border: 1px solid green; */
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    padding: 0 20px;
    text-align: center;
}
.service-name {
    margin: 15px 0;
    font-size: 20px;
    font-weight: 600;
    color: #D72127;
}
.services-process {
    /* border: 1px solid blue; */
    margin-top: 30px;

}
.process-title {
    font-size: 30px;
    font-weight: 700;
}
.process-container {
    margin-top: 20px;
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: row;
    gap: 20px;

}
.each-process {
    /* background-color: #D72127; */
    /* color: white; */
    border: 3px solid #D72127;
    width: 100%;
    border-radius: 30px;
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.each-process .num {
    font-size: 50px;
    font-weight: 700;
    color: #D72127;
}
.each-process .heading {
    font-size: 20px;
    font-weight: 700;
    margin: 10px 0;
    color: #F7BD23;
}
@media only screen and (max-width: 480px) {
    .process-container {
        flex-direction: column;
        gap: 20px;
    }
}

/* about styles here */
.about {
    background-color: black;
    color: white;
    /* height: 80vh; */
}
.about .white-title {
    color: white;
}
.about-container {
    /* border: 1px solid red; */
    height: 60vh;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
}
@media only screen and (max-width: 480px) {
    .about-container {
        height: auto;
    }
}
.about-container > div {
    /* border: 1px solid pink; */
    width: 100%;
}
.about-text {
    font-size: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.about-art-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.about-art {
    height: 300px;
}
.about-more {
    /* border: 1px solid blue; */
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;

}
.about-more > div {
    border: 2px solid #F7BD23;
    width: 100%;
    background: #F7BD23;
    border-radius: 40px;
    padding: 20px;
}
.about .button {
    /* background-color: #04AA6D; */
    border: none;
    color: white;
    border: 1px solid white;
    border-radius: 30px;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    margin-right: 10px;
    width: 170px;
    margin-top: 30px;
}
.about .button:hover {
    background-color: white;
    border: none;
    color: black;
    border: 1px solid white;
    border-radius: 30px;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 30px;
}
@media only screen and (max-width: 480px) {
    .about-container {
        flex-direction: column;
    }
    .about-art {
        height: 180px;
        margin-top: 30px;
    }
}

/* contact styles here */
.contact {
    /* height: 50vh; */
    background-color: white;
}
.contact-title {
    font-size: 30px;
    font-weight: 700;
    color: #D72127;
}
.contact-container {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    /* height: 100%; */
}

.contact-container > div {
    /* border: 1px solid pink; */
    width: 100%;
}
.contact-art {
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact-art img {
    height: 350px;
}

.contact-form {
    display: flex;
    align-items: center;
    justify-content: center;
}
.form-container {
    /* border: 1px solid red; */
    width: 400px;
}
form {
    display: flex;
    flex-direction: column;
}
input, textarea {
    background-color: #F8F8F8;
    /* border: 1px solid black; */
    color: black;
    font-size: 16px;
}
/* input[type=text], input[type=email] {
    border-radius: 30px;
} */
input[type=text], input[type=email], select, textarea {
    width: 100%;
    padding: 15px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 30px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-top: 15px;
    resize: vertical;
    -webkit-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
}
textarea {
    max-height: 100px;
    margin-bottom: 15px;
    font-family: 'Roboto';
}
.captcha {
    margin-bottom: 15px;
}
input:focus, textarea:focus, select:focus {
    outline-offset: 0px !important;
    outline: none !important;
    border: 3px solid #F7BD23 !important;
}
.contact-btn {
    background-color: white;
    border: none;
    color: black;
    border: 1px solid black;
    border-radius: 30px;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    margin-right: 10px;
}
.contact-btn:hover {
    background-color: black;
    border: none;
    color: white;
    border: 1px solid black;
    border-radius: 30px;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    margin-right: 10px;
}
@media only screen and (max-width: 480px) {
    .contact-container {
        flex-direction: column;
    }
    .contact-art img {
        height: 230px;
    }
    .form-container {
        margin-top: 50px;
    }
}
@media only screen and (max-width: 768px) {
    .contact-container {
        flex-direction: column;
    }
    .form-container {
        margin-top: 50px;
    }
}

/* footer styles here */
.footer {
    /* height: 200px; */
    background-color: #F8F8F8;
    color: #7e7e7e;
    font-size: 14px;
}
.grid-footer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
}
.footer .grid-footer > div {
    /* border: 1px solid green; */
}
.footer-left {
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
}
.footer-left > span {
    padding: 3px 0;
}
.footer-left a {
    text-decoration: none;
    color: #7e7e7e;
    transition: all 300ms;
}
.footer-left a:hover {
    color: #F7BD15;
    transition: all 300ms;
}
.footer-right {
    display: flex;
    flex-direction: column;
}
.footer-right > span {
    padding: 3px 0;
}

.footer-right .velux {
  width: 150px;
}

.home-form {
    display: none;
}