.container {
    /* margin: 0 auto;  */
    /* max-width: 1200px; */
    /* border: 1px solid red; */
    top: 0;
    position: fixed;
    width: 100% !important;
}

.active {
    color: #F7BD23 !important;
    transition: all 100ms;
    font-weight: 700;
}

.navbar {
    top: 0;
    position: fixed;
    z-index: 9999 !important;
    width: 100% !important;
    background-color: white !important;
    transition: all 300ms;
    -webkit-box-shadow: 0px -2px 12px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px -2px 12px -3px rgba(0,0,0,0.75);
    box-shadow: 0px -2px 12px -3px rgba(0,0,0,0.75);
}

.logo {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.logo-main {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
}
.logo-container {
    display: flex;
    flex-direction: column;
    color: #DEAC27;
    margin-left: 10px;
}
.logo-name {
    font-size: 20px;
    font-weight: bolder;
}
.logo-subname {
    font-size: 13px;
}